@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');


.is_auth_page .App {
  margin-left: 0 !important;
  width: 100%;
}


.ql-container {
  min-height: 150px;
  max-height: 150px;
  overflow: auto;
}

.auth_wrapper {
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

::placeholder {
  color: #9D9FA3;
  opacity: 1;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #9D9FA3;
  font-weight: 300;
}

.auth_box {
  width: 420px;
  padding: 20px;
}

.auth_logo {
  width: 200px;
}

.auth_box h3 {
  font-family: 'Merriweather', sans-serif;
  font-size: 21px;
  color: #00205B;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  font-family: 'Montserrat', sans-serif;
  padding: 10px;
  font-size: 14px;
}

.btn-primary {
  background: #00205B;
  border-color: #00205B;
  font-family: 'Montserrat', sans-serif;
  padding: 8px;
}

.btn-outline-secondary {
  padding: 8px;
}

.fp_link {
  color: #00205B;
  font-size: 14px;
}

.cm_checkbox_wrpper {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #FF5C39;
  border-radius: 4px;
  vertical-align: sub;
  position: relative;
  margin-right: 10px;
}

.cm_checkbox_wrpper input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.cm_checkbox_wrpper span {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FF5C39;
  left: 0;
  top: 0;
  display: none;
}

.cm_checkbox_wrpper span:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 10px;
  background: #fff;
  transform: rotate(35deg);
  left: 8px;
  top: 2px;
  border-radius: 4px;
}

.cm_checkbox_wrpper span:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 6px;
  background: #fff;
  transform: rotate(-54deg);
  left: 4px;
  top: 6px;
  border-radius: 4px;
}

.cm_checkbox_wrpper input:checked + span {
  display: block;
}





.App {
  width: calc(100% - 280px);
  margin-left: 280px;
  min-height: 100vh;
}

.cm_sidebar {
  position: fixed;
  background: #00205B;
  width: 280px;
  min-height: 450px;
  height: 100vh;
  left: 0;
  top: 0;
  font-family: 'Montserrat', sans-serif;
}

.cm_logo {
  display: block;
  margin: 30px auto;
  width: 140px;
}

.cm_sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 50px;
}

.cm_sidebar ul li a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  padding: 15px 10px;
  cursor: pointer;
  padding-left: 40px;
  opacity: 0.65;
}

.cm_sidebar ul li a.active,
.cm_sidebar ul li a:hover {
  opacity: 1;
}

.cm_sidebar ul li a.active {
  background: rgb(159 162 180 / 8%);
  position: relative;
}

.cm_sidebar ul li a.active:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background: #FF5C39;
  left: 0;
  top: 0;
}

.cm_sidebar ul li a img {
  display: inline-block;
  width: 22px;
  vertical-align: top;
  margin-right: 15px;
}


.admin_bar {
  background: #E1D3CA;
  padding: 15px 20px;
  font-size: 21px;
  font-weight: 600;
  font-family: Montserrat;
  color: #00205B;
  line-height: 1;
}

header h4 {
  font-family: 'Merriweather', sans-serif;
  font-size: 24px;
}




.table {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  border: 1px solid #DBDBDB;
  color: #00205B;
}

.table th {
  background: #00205B;
  color: #fff;
  font-weight: 600;
  padding: 15px;
  border: 0;
  min-width: 150px;
}

.table td {
  border: 0;
  color: #00205B;
}

.bg_light_pink {
  background-color: #F9F6F4 !important;
}

img.event_img_sm {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.page_top_actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_top_actions .btn svg {
  display: inline-block;
  max-width: 20px;
  height: 20px;
}

.delete_btn {
  position: absolute;
  right: 10px;
  top: 4px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.event_img {
  max-width: 100%;
}

.quill.is-invalid .ql-snow {
  border-color: #dc3545;
}

.cm_f_err {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

span.cm_threedots {
  width: 350px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cm_search {
  position: relative;
}

.cm_search svg {
  position: absolute;
  left: 12px;
  top: 12px;
  width: 20px;
}

.cm_search input {
  width: 320px;
  padding-left: 40px;
}

.table_action_icon {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}

.cm_err {
  color: red;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
}

span.cm_check_box span {
  display: inline-block;
  width: 40px;
  height: 20px;
  background: #c8c8c8;
  border-radius: 100px;
  margin-left: 10px;
  position: relative;
}

span.cm_check_box {
  display: inline-block;
  position: relative;
  top: 6px;
  margin-left: 10px;
}

span.cm_check_box input {position: absolute;left: 0;top: 0;width: 100%;height: 100%;cursor: pointer;z-index: 9;opacity: 0;}

span.cm_check_box span:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 100%;
  left: 2px;
  top: 2px;
  transition: all 0.3s;
}

span.cm_check_box input:checked + span {
  background: green;
}

span.cm_check_box input:checked + span::after {
  left: 22px;
}

@keyframes spinner1 {
  100% { transform: rotate(360deg); }
}
@keyframes spinner2 {
  0% { stroke-dasharray: 0 150; stroke-dashoffset: 0; }
  47.5% { stroke-dasharray: 42 150; stroke-dashoffset: -16; }
  95%, 100% { stroke-dasharray: 42 150; stroke-dashoffset: -59; }
}